import React from 'react';
import Kirby from '../components/Kirby';
import { Link } from 'react-router-dom';
import arrowImage from '../images/pinkarrow.png'; 

function Welcome() {
    return (
        <div>
            <div className='KirbyStart'>
                <h1 className='ScholarlyAmbition'>Hii Welcome to Jolia's Place :)</h1>
                <img src={arrowImage} alt="Click on Kirby" className="animated-arrow"/>
                <div>
                    <Link to="home">
                        <Kirby/>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default Welcome;