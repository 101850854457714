import React from 'react'
import Navbar from '../components/NavBar'
import CarVideosCard from '../components/CarVideosCard';
import { Link } from 'react-router-dom';


function Projects () {
    return (
    <div> 
        <Navbar/>
        <Link to="/projects/carvideos">
            <div className="project-item">
                <CarVideosCard />
            </div>
        </Link>
    </div>
    )
}

export default Projects