import React from 'react'
import Navbar from '../components/NavBar'
import PhotoGrid from '../components/photogrid';
import jeanskirt from '../images/JT_JeanSkirt.jpg';
import jersey from '../images/JT_preformancejersey.jpg';
import furlined from '../images/JT_furlinedhoodie.jpg';
import dress from '../images/JT_upcycleddress.jpg';

const photos = [
    { src: jeanskirt, description: 'A skirt I upcycled from a pair of jeans, blending functionality with style by transforming denim into a wearable statement piece.' },
    { src: jersey, description: 'Racing performance jersey crafted with conductive thread and integrated sensors. This piece is a modified Alpinestars jersey, showcasing innovation through "frankensteined" engineering for both style and function.' },
    { src: furlined, description: 'A thrifted hoodie reimagined with fur lining on the hood and tailored to accentuate the waist, merging comfort and a structured fit for a sleek yet cozy look.' },
    { src: dress, description: 'A one-of-a-kind dress crafted from an assortment of thrifted fabrics, featuring cool textures in a white and beige palette, highlighting the beauty of upcycling and creative design.' },
];

function Clothing() {
    return (
        <div>
            <Navbar />
            <PhotoGrid photos={photos}/>
        </div>
    );
}

export default Clothing;
