import React from "react";
import Navbar from "../../components/NavBar";
import carvideo1 from '../../videos/carvideo1.mp4';
import carvideo2 from '../../videos/carvideo2.mp4';

function Carvideos() {
    const videos = [
        { id: 1, src: carvideo1, title: "Car Video 1" },
        { id: 2, src: carvideo2, title: "Car Video 2" },

    ];

    return (
        <div>
            <Navbar />

            <div style={styles.page}>
            <p className="ScholarlyAmbitionShadowContactPage">
                 These videos are all recorded by me and edited by me. Some of them are recorded on the DJI Pocket three, mounted onto the back of a car.
            </p>

                <div style={styles.grid}>
                    {videos.map((video) => (
                        <div key={video.id} style={styles.card}>
                            <div style={styles.videoWrapper}>
                                <video controls style={styles.video}>
                                    <source src={video.src} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <p style={styles.title}>{video.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const styles = {
    page: {
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        gap: "20px",
        marginTop: "20px",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f1f1f1",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    videoWrapper: {
        width: "100%",
        height: "0",
        paddingTop: "56.25%", // Aspect ratio 16:9
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
        backgroundColor: "#000", // Placeholder for when the video is loading
    },
    video: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain", // Ensures videos maintain their aspect ratio
    },
    title: {
        marginTop: "10px",
        fontSize: "1rem",
        fontWeight: "bold",
    },
};

export default Carvideos;
