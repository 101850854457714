import React from 'react';

const CarVideosCard = () => {
  return (
    <div className="carvids-card-container">
      <div className="carvids-text-content">
        <h1>car videos :)</h1>
        <p>i like recording and taking pictures of cool cars</p>
      </div>
      <div className="carvids-image-placeholder"></div>
    </div>
  );
};

export default CarVideosCard;